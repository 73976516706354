html {
  background-color: #d9e4e1;
}

body {
  font-family: 'Raleway' !important;
}

h2 {
  font-size: 4vw !important;
}

p {
  font-size: 1.5vw !important;
}

.header {
  background-color: #d9e4e1;
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 10vw;
  z-index: 10;
  padding: 0 1rem !important;
  max-height: 5rem;
}

.header a {
  color: #000000;
}

.header-logo {
  width: 7vw;
  height: 7vw;
  max-height: 4rem;
  max-width: 4rem;
  min-width: 1rem;
  min-height: 1rem;
}

.content {
  margin-top: 8vw;
}

.large-content {
  height: 68vw;
  max-height: 800px;
  position: relative;
}

.inner-content {
  height: 34vw;
  max-height: 400px;
}

.text-content {
  height: 100px;
}

.text-block {
  position: absolute;
}

.footer {
  background-color: #2B2B2B;
  color: #fff;
}

.footer-content {
  display: block;
  margin: auto 0;
  height: 100px;
}

@media screen and (min-width: 2561px) {
  .content {
    margin-top: 3vw;
  }
  
  .header {
    height: 3vw;
    max-height: 3vw;
  }

  .large-content {
    max-height: 1500px;
  }
  
  .inner-content {
    max-height: 750px;
  }

  .text-block {
    left: 3vw !important;
  }
}

@media screen and (min-width: 1600px) {
  h2 {
     font-size: 3.5rem !important;
  }

  p {
    font-size: 1.9rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .text-block {
    bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 1rem !important;
 }

 p {
   font-size: .8rem !important;
 }
}